.header {
  background-color: var(--color-white);
  max-width: calc(100vw - 64px);
  margin: 0 auto;
  padding: 24px 0;
}

@media (min-width: 900px) {
  .header {
    max-width: calc(100vw - 80px);
  }
}

@media (min-width: 1200px) {
  .header {
    max-width: 1200px;
  }
}

.logoBreadcrumbs {
  display: flex;
  align-items: center;
  gap: 24px;
}

.signout {
  color: var(--color-teja);
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
