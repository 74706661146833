@import 'src/styles/variables';

.grid {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(4, [col] 1fr);

  @media (min-width: $bp2) {
    gap: 48px;
    grid-template-columns: repeat(8, [col] 1fr);
  }
}

.gridContainer {
  max-width: calc(100vw - 64px);
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $bp1) {
    max-width: calc(100vw - 80px);
  }

  @media (min-width: 1280px) {
    max-width: 1200px;
  }

  &.wide {
    @media (max-width: $bp1) {
      max-width: none;
    }
  }
}
