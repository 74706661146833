@import 'src/styles/variables';

.items {
  padding: 0;
  margin: 0;
  list-style-type: none;

  @media (min-width: $bp1) {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 40px;
  }
}

.item {
  display: flex;
  margin-top: 38px;

  @media (min-width: $bp1) {
    margin-top: 0;
  }
}

.icon {
  margin-right: 40px;

  svg {
    width: 58px;
    height: 58px;
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.text {
  font-size: 24px;
  line-height: 28px;
  color: $color-gray3;
  margin-top: 8px;
  margin-bottom: 0;
}
