@import 'src/styles/variables';

.grid {
  @media (min-width: $bp1) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 40px;
  }
}

.intro {
  margin-top: 40px;
  p {
    margin: 0;
  }
}

.mob {
  margin: 24px 0;

  @media (min-width: $bp1) {
    display: none;
  }
}

.desk {
  display: none;

  @media (min-width: $bp1) {
    margin: 16px 0 0;
    display: block;
  }
}
