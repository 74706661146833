//MEDIA QUERIES
$mq1max: 599px;
$bp1: 600px;
$mq2max: 899px;
$bp2: 900px;
$bp3: 1200px;
$bp4: 1680px;

// COLORS
$color-attention0: #fff9e8;
$color-attention1: hsl(48 100% 90%);
$color-attentionActive: hsl(48 100% 75%);
$color-attention: #ffda05;
$color-attentionHover: hsl(51 100% 46%);
$color-attentionDark1: hsl(51 100% 45%);
$color-bottle: #075d52;
$color-bottleLight: #f1fffe;
$color-potus: #00a69c;
$color-error: #f25c5a;
$color-errorBackground: hsl(1 85% 90%);
$color-errorActive: hsl(1 85% 55%);
$color-red5: #af110e; //hsl(1; 85%; 37%);
$color-dark3: #cfdbe2;
$color-dark2: hsl(202 25% 45%);
$color-dark: #1e2e28;
$color-green: hsl(161 18% 69%);
$color-greenText: hsl(161 18% 45%);
$color-lightGreen: hsl(86 21% 94%);
$color-pink: #f5e8e3;
$color-lightGrey: hsl(228 24% 95%);
$color-gray0: #ecf4f1;
$color-gray020: rgba(236, 244, 241, 0.2);
$color-gray020NoAlpha: #fcfcfc;
$color-gray2: #b2c2bc;
$color-gray3: #76807c;
$color-gray4: #c9ddd6;
$color-gray0Hover: #dbebe5;
$color-gray: #9fa09f;
$color-white: #ffffff;
$color-white0: #fffef9;
$color-black: #000000;
$color-red1: #fdd0cf;
$color-teja: #de8069;
$color-tejaHover: hsl(12 64% 44%);
$color-tejaLight: #ffdfdf;
$color-marmolClaro: #ffecea;
